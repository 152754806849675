/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */

import React, { useState, useReducer, useEffect, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Select, { components } from "react-select";
import { useEffectOnce } from "usehooks-ts";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FileSaver from "file-saver";
// import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

import { checkIsLoggedIn, config, firmwareVersion, getAccessToken, refreshToken, sleep } from "../util/common";
import Canvas from "./Canvas";
import Snowflakes from "./Snowflake";
import Notif from "./ChangelogNotif";
// import Canvas2 from "./Canvas2";

interface ModeOption {
  value: number;
  label: string;
  isDisabled: boolean;
};

type boardType = 'lite_v4' | 'lite_v3' | 'lite_v2' | 'v3' | 'v4'

const Led = ({
  ledOnMs,
  ledOffMs,
}: {
  ledOnMs: number;
  ledOffMs: number;
}) => {
  const [isLedOn, setIsLedOn] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (ledOnMs <= 25 && ledOffMs <= 25) {
        setIsLedOn(true);
      } else {
        setIsLedOn(prevIsOn => {return !prevIsOn});
      }
    }, isLedOn ? ledOnMs : ledOffMs);

    return () => {return clearInterval(interval)};
  }, [isLedOn, ledOnMs, ledOffMs]);

  return (
      <h4 className="d-flex justify-content-center servo-num led-container">
        {isLedOn && (
          <img className="d-flex justify-content-center led-picture" alt="led" src="/led_on.png" />
        )}
        {!isLedOn && (
          <img className="d-flex justify-content-center led-picture" alt="led" src="/led_off.png" />
        )}
      </h4>
  );
}

// const BoardImage = ({board}: {board: boardType}) => {
//   return <>
//     <img className={`board-picture ${board === 'lite_v3' ? '' : 'hidden'}`} alt='lite_v3' src='lite_v3.png' />
//     <img className={`board-picture ${board === 'lite_v2' ? '' : 'hidden'}`} alt='lite_v2' src='lite_v2.png' />
//     <img className={`board-picture ${board === 'v3' ? '' : 'hidden'}`} alt='v3' src='v3.png' />
//     <img className={`board-picture ${board === 'v4' ? '' : 'hidden'}`} alt='v4' src='v4.png' />
//   </>;
// };

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [mode, setMode] = useState(1);
  const [board, setBoard] = useState<boardType>('lite_v4');
  const [showKrylan, setShowKrylan] = useState(false);

  const defaultAnglesSingleDrop = [0, 180];
  const defaultAnglesDoubleDrop = [90, 45, 135];
  const defaultAnglesDoubleDropSeq = [0, 90, 180];

  const defaultAnglesKrylan = [0, 90, 180];

  const [angleInactive, setAngleInactive] = useState(defaultAnglesSingleDrop[0]);
  const [angleActive1, setAngleActive1] = useState(defaultAnglesSingleDrop[1]);
  const [angleActive2, setAngleActive2] = useState(0);
  const [angleActive3, setAngleActive3] = useState(0);

  const [angle2Inactive, setAngle2Inactive] = useState(0);
  const [angle2Active1, setAngle2Active1] = useState(0);
  const [angle2Active2, setAngle2Active2] = useState(0);
  const [angle2Active3, setAngle2Active3] = useState(0);

  const lightSensorThresholdDefaultConst = 60;
  const lightSensorThresholdDefaultV3V4Const = 70;

  const [lightSensorThresholdDefault, setLightSensorThresholdDefault] = useState(lightSensorThresholdDefaultConst);
  const [lightSensorThreshold, setLightSensorThreshold] = useState(lightSensorThresholdDefaultConst);
  const [numOfServos, setNumOfServos] = useState(1);
  const [ledOnMs, setLedOnMs] = useState(250);
  const [ledOffMs, setLedOffMs] = useState(250);
  const [doMirror, setDoMirror] = useState(false);

  const [isAfter17062023, setIsAfter17062023] = useState(true);
  const [isGL5516, setIsGL5516] = useState(false);

  const [shimFrom, setShimFrom] = useState(500);
  const [shimTo, setShimTo] = useState(2400);

  const defaultDynamicPower = true;
  const [dynamicPower, setDynamicPower] = useState(defaultDynamicPower);

  const [reRender, setReRender] = useState(true);
  const [rnd, setRnd] = useState(0);

  const [formDisabled, setFormDisabled] = useState(false);

  // const jaga = new Audio("/jaga-jaga.mp3")
  const nato = new Audio("/nato-v-chatu.mp3")

  const singleDropModes = [1, 6];
  const doubleDropModes = [2, 3, 9];

  const defaultModeOptions: ModeOption[] = [
    { value: 1, label: "Односкид", isDisabled: false },
    { value: 2, label: "Двускид (послідовний, літній варінт)", isDisabled: false },
    { value: 3, label: "Двускид (послідовний, зимній варінт)", isDisabled: false },
    // { value: 4, label: "1 серво, 3 позиції (по черзі)" },
    // { value: 5, label: "2 серви, 2 позиції кожна" },
  ];

  const shim90ModeId = 1;
  const shim180ModeId = 2;
  const shimCustomModeId = 3;

  const [shimMode, setShimMode] = useState(shim90ModeId);

  const shimOptions: ModeOption[] = [
    { value: shim90ModeId, label: "90 градусні серви", isDisabled: false },
    { value: shim180ModeId, label: "180 градусні серви", isDisabled: false },
    { value: shimCustomModeId, label: "Свої налаштування", isDisabled: false },
    // { value: 4, label: "1 серво, 3 позиції (по черзі)" },
    // { value: 5, label: "2 серви, 2 позиції кожна" },
  ];

  const singleDropModeId = 6;
  const flipFlopModeId = 7;
  const doubleSeqModeId = 9;
  const krylanModeId = 5;

  const lv3V3V4Options: ModeOption[] = [
    { value: singleDropModeId, label: "Односкид", isDisabled: false },
    { value: flipFlopModeId, label: "Двускид з центральною точкою (Flip-Flop)", isDisabled: false },
    { value: doubleSeqModeId, label: "Двускид (послідовний)", isDisabled: false },
    { value: krylanModeId, label: "Двускид (Крилан) - тільки для двух серво", isDisabled: true },
    // { value: 8, label: "3 позиції (трискид)" },
  ];

  // TODO: refactor this somehow
  const lv3V3V4OptionsDisabled: ModeOption[] = [
    { value: singleDropModeId, label: "Односкид", isDisabled: false },
    { value: flipFlopModeId, label: "Двускид з центральною точкою (Flip-Flop)", isDisabled: false },
    { value: doubleSeqModeId, label: "Двускид (послідовний) - тільки для одного серво", isDisabled: true },
    { value: krylanModeId, label: "Двускид (Крилан)", isDisabled: false },
    // { value: 8, label: "3 позиції (трискид)" },
  ];

  const [modeOptions, setModeOptions] = useState<ModeOption[]>(defaultModeOptions);

  const renderErrorMessage = (name: any) => {
    return (
      name === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
      )
    );
  };

  const playNato = () => {
    try {
      const natoInChata = localStorage.getItem("nato");
      if (natoInChata !== 'true') {
          nato.play();
          localStorage.setItem("nato", "true");
      }
    } catch (err) {
      console.error(err);
    }
  }

  const checkAccess = async () => {
      const response = await fetch(`${config.API_URL}/user/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          "Authorization": `Bearer ${getAccessToken()}`,
        },
      });
      const json = await response.json();
      return json?.access;
  }

  useEffectOnce(() => {
    (async () => {
      const isLoggedIn = await checkIsLoggedIn();
      if (!isLoggedIn) {
        setLoggedIn(false);
        window.location.href = "/login";
        return;
      }
      playNato();
      setLoggedIn(true);
      const access = await checkAccess();
      if (access === 'granted') {
        setShowKrylan(true);
      } else {
        setShowKrylan(false);
      }
    })();
  });

  const getTimestamp = () => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Etc/GMT-3',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(date);
    const formattedDate = `${parts[2].value}.${parts[0].value}.${parts[4].value}_${parts[6].value}-${parts[8].value}-${parts[10].value}`;
    return formattedDate;
  }

  const handleSubmit = async (event: any): Promise<any> => {
    if (event) {
      event.preventDefault();
    }
    try {
      const data: any = {
        board,
        mode,
        inactive1: angleInactive,
        active1: angleActive1,
        active2: null,
        // active3: angleActive3,
        inactive2: null,
        active21: null,
        active22: null,
        // active23: null,
        lightSensorThreshold,
        ledOnMs,
        ledOffMs,
        numOfServos,
        shimFrom,
        shimTo,
        dynamicPower
      };
      if (mode !== 1 && mode !== 6) {
        data.active2 = angleActive2;
      }
      if (numOfServos === 2) {
        data.numOfServos = numOfServos;
        data.inactive2 = angle2Inactive;
        data.active21 = angle2Active1;
        data.active22 = angle2Active2;
        // data.active23 = angle2Active3;
        // if (board === 'v3' || board === 'v4') {
        // }
      }
      const response = await fetch(`${config.API_URL}/compile/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          "Authorization": `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      if (json?.code === 'token_not_valid') {
        await refreshToken();
        return handleSubmit(null);
      }
      if (response.status !== 200) {
        console.error(json);
        setErrorMessages({
          name: 'general',
          message: json.error,
        })
        return null;
      }
      // try {
      //   jaga.play();
      // } catch (err) {
      //   console.error(err);
      // }
      setErrorMessages({});
      const blob = new Blob([json.hexData], {type: "text/plain;charset=us-ascii"});
      const timestamp = getTimestamp();
      let fwSuffix = '';
      if (mode === 5) {
        fwSuffix = '_krylan';
      }
      const filename = `${board}${fwSuffix}_fw_v${firmwareVersion}_${timestamp}.hex`;
      const file = new File([blob], filename, {type: "application/x-hex;charset=utf-8"});
      FileSaver.saveAs(file)
      await sleep(7000);
    } catch (e) {
      console.error(e);
      setErrorMessages({
        name: 'general',
        message: "Немає зв'язку зі сервером! 😭",
      });
    }
    return null;
  };

  const setSingleDropAngles = (_board: string = '', _mode: number = -1) => {
    setAngleInactive(defaultAnglesSingleDrop[0]);
    setAngleActive1(defaultAnglesSingleDrop[1]);
    setAngleActive2(0);
    setAngleActive3(0);
    setAngle2Inactive(defaultAnglesSingleDrop[0])
    setAngle2Active1(defaultAnglesSingleDrop[1])
    setAngle2Active2(0);
    setAngle2Active3(0);
  }

  const setDoubleDropAngles = (_board: string = '', _mode: number = -1) => {
    if (_mode === doubleSeqModeId) {
      setAngleInactive(defaultAnglesDoubleDropSeq[0]);
      setAngleActive1(defaultAnglesDoubleDropSeq[1]);
      setAngleActive2(defaultAnglesDoubleDropSeq[2]);
      setAngleActive3(0);
      setAngle2Inactive(defaultAnglesDoubleDropSeq[0])
      setAngle2Active1(defaultAnglesDoubleDropSeq[1])
      setAngle2Active2(defaultAnglesDoubleDropSeq[2]);
      setAngle2Active3(0);
    } else if (_mode === krylanModeId) {
      setAngleInactive(defaultAnglesKrylan[0]);
      setAngleActive1(defaultAnglesKrylan[1]);
      setAngleActive2(defaultAnglesKrylan[2]);
      setAngleActive3(0);
      setAngle2Inactive(defaultAnglesDoubleDrop[0])
      setAngle2Active1(defaultAnglesDoubleDrop[1])
      setAngle2Active2(defaultAnglesDoubleDrop[2]);
      setAngle2Active3(0);
    } else {
      setAngleInactive(defaultAnglesDoubleDrop[0]);
      setAngleActive1(defaultAnglesDoubleDrop[1]);
      setAngleActive2(defaultAnglesDoubleDrop[2]);
      setAngleActive3(0);
      setAngle2Inactive(defaultAnglesDoubleDrop[0])
      setAngle2Active1(defaultAnglesDoubleDrop[1])
      setAngle2Active2(defaultAnglesDoubleDrop[2]);
      setAngle2Active3(0);
    }
    setReRender(!reRender);
  }

  const getDefaultAngle = (angleIndex: number): number => {
    if (singleDropModes.includes(mode)) {
      if (angleIndex < defaultAnglesSingleDrop.length) {
        return defaultAnglesSingleDrop[angleIndex];
      }
      return 0;
    }
    if (mode === doubleSeqModeId) {
      if (angleIndex < defaultAnglesDoubleDropSeq.length) {
        return defaultAnglesDoubleDropSeq[angleIndex];
      }
      return 0;
    }
    if (mode === krylanModeId) {
      if (angleIndex < defaultAnglesKrylan.length) {
        return defaultAnglesKrylan[angleIndex];
      }
      return 0;
    }
    if (angleIndex < defaultAnglesDoubleDrop.length) {
      return defaultAnglesDoubleDrop[angleIndex];
    }
    return 0;
  }

  const isLite = (newBoard: string = '') =>  {
    if (newBoard) {
      return newBoard.startsWith('lite');
    }
    return board.startsWith('lite');
  }

  const updateLightSensorThreshold = (_board: string, dateAfter: boolean, gl: boolean) => {
    if (dateAfter && gl) {
      setLightSensorThresholdDefault(lightSensorThresholdDefaultV3V4Const);
      setLightSensorThreshold(lightSensorThresholdDefaultV3V4Const);
    } else if (dateAfter && !gl) {
      setLightSensorThresholdDefault(isLite(_board) ? lightSensorThresholdDefaultConst : lightSensorThresholdDefaultV3V4Const);
      setLightSensorThreshold(isLite(_board) ? lightSensorThresholdDefaultConst : lightSensorThresholdDefaultV3V4Const);
    } else if (!dateAfter) {
      setLightSensorThresholdDefault(93);
      setLightSensorThreshold(93);
    }
  }

  const onSelectBoard = (event: any) => {
    setFormDisabled(event.value !== 'lite_v4' && event.value !== 'lite_v3' && event.value !== 'lite_v2' && event.value !== 'v3' && event.value !== 'v4');
    if (isLite(event.value) === false) {
      setIsAfter17062023(true);
      setIsGL5516(true);
      updateLightSensorThreshold(event.value, true, true);
    } else {
      setIsAfter17062023(true);
      setIsGL5516(false);
      updateLightSensorThreshold(event.value, true, false);
    }
    setShimMode(shim90ModeId);
    setShimFrom(500);
    setShimTo(2400);
    setDynamicPower(defaultDynamicPower);
    setMode(6);
    setNumOfServos(1);
    setSingleDropAngles(board, 6);
    setReRender(!reRender);
    setRnd(Math.random()); // Re-render the fucking mode selector
    setBoard(event.value);
  }

  const onSelectMode = (event: any) => {
    setMode(event.value);
    setShimMode(shim90ModeId);
    setShimFrom(500);
    setShimTo(2400);
    if (event.value === doubleSeqModeId) {
      setNumOfServos(1)
    }
    if (singleDropModes.includes(event.value)) {
      setSingleDropAngles(board, event.value);
    } else {
      setDoubleDropAngles(board, event.value);
    }
    setReRender(!reRender);
  }

  const onSelectShim = (event: any) => {
    setShimMode(event.value);
    if (event.value === shim90ModeId) {
      setShimFrom(500);
      setShimTo(2400);
    } else if (event.value === shim180ModeId) {
      setShimFrom(1000);
      setShimTo(2000);
    } else if (event.value === shimCustomModeId) {
      setShimFrom(500);
      setShimTo(2400);
    }
    setReRender(!reRender);
  }

  const onShimFromChange = (event: any) => {
    setShimFrom(event.target.value);
  }

  const onShimToChange = (event: any) => {
    setShimTo(event.target.value);
  }

  const onSelectNumOfServos = (event: any) => {
    if (event.target.id === 'num-of-servos-1' && event.target.value === 'on') {
      setNumOfServos(1);
    } else {
      setNumOfServos(2);
    }
    setMode(6);
    setSingleDropAngles(board, 6);
    setRnd(Math.random()); // Re-render the fucking mode selector
    setReRender(!reRender);
  }

  const onChange = (event: any) => {
    if (event.target.name === 'light-sensor-threshold') {
      // event.target.value = event.target.value.replace(/\D/g,'');
      setLightSensorThreshold(Number(event.target.value));
    }
    if (event.target.name === 'led-blink-on-ms') {
      setLedOnMs(Number(event.target.value || 0));
    }
    if (event.target.name === 'led-blink-off-ms') {
      setLedOffMs(Number(event.target.value || 0));
    }
  };

  const boardOptions = [
    { value: "lite_v4", label: "Bavovna Lite v4 & v4.1" },
    { value: "lite_v3", label: "Bavovna Lite v3" },
    { value: "lite_v2", label: "Bavovna Lite v2" },
    // { value: "lite_v2", label: "Bavovna Lite v2" },
    // { value: "v2", label: "Bavovna v2 (NOT SUPPORTED YET)" },
    { value: "v4", label: "Bavovna v4" },
    { value: "v3", label: "Bavovna v3" },
  ];

  return (
    <Container>
      <Notif />
      <Row md="6" className="justify-content-center">
        <Col md={numOfServos === 2 ? 12 : 6} className="justify-content-center">
          {loggedIn && (
            <div className="bavovna-form st">
              <form onSubmit={handleSubmit}>
                <div className="input-container">
                  <label htmlFor="board-selector">Тип плати</label>
                  <Row>
                    <Col md={12} lg={6}>
                      <Select
                        id="board-selector"
                        defaultValue={boardOptions[0]}
                        options={boardOptions}
                        isSearchable={false}
                        isClearable={false}
                        onChange={onSelectBoard}
                      />
                      </Col>
                    <Col className="d-flex d justify-content-center" md={12} lg={6}>
                      {/* <BoardImage board={board} /> */}
                      {board === 'lite_v2' && (
                        <img className="board-picture" alt='lite_v2' src='lite_v2.png' />
                      )}
                      {board === 'lite_v3' && (
                        <img className="board-picture" alt='lite_v3' src='lite_v3.png' />
                      )}
                      {board === 'lite_v4' && (
                        <img className="board-picture" alt='lite_v4' src='lite_v4.png' />
                      )}
                      {/* {board === 'lite_v2' && (
                        <img className="board-picture" alt='lite_v2' src='lite_v2.png' />
                      )} */}
                      {board === 'v3' && (
                        <img className="board-picture" alt='v3' src='v3.png' />
                      )}
                      {board === 'v4' && (
                        <img className="board-picture" alt='v4' src='v4.png' />
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="input-container">
                  <label htmlFor="board-selector">Режим</label>
                  {['lite_v2', 'lite_v3', 'lite_v4', 'v3', 'v4'].includes(board) === false && (
                    <Select
                      id="mode-selector"
                      value={modeOptions[mode - 1]}
                      defaultValue={modeOptions[0]}
                      options={modeOptions}
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={formDisabled}
                      onChange={onSelectMode}
                    />
                  )}
                  {['lite_v2', 'lite_v3', 'lite_v4', 'v3', 'v4'].includes(board) && (
                    <Select
                      id="mode-selector"
                      // value={v3v4options[mode - 1]}
                      // key={`${mode}-${numOfServos}`}
                      key={rnd}
                      defaultValue={numOfServos === 1 ? lv3V3V4Options[0] : lv3V3V4OptionsDisabled[0]}
                      options={(numOfServos === 1 ? lv3V3V4Options : lv3V3V4OptionsDisabled).filter(opt => {
                        // if (numOfServos !== 1) {
                        //   // Disable flip-flop for 2 servos
                        //   return opt.value !== doubleSeqModeId
                        // }
                        if (showKrylan === false && opt.value === krylanModeId) {
                          return false;
                        }
                        return true;
                      })}
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={formDisabled}
                      onChange={onSelectMode}
                    />
                  )}
                </div>
                {['lite_v2', 'lite_v3', 'lite_v4', 'v3', 'v4'].includes(board) && (
                  <div className="input-container mt-4">
                    <label htmlFor="board-selector">Кількість серв</label>
                    {/* <p className="hint">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    <div key="num-of-servos" className="mb-3">
                      <Row>
                        <Col>
                          <Form.Check 
                            // defaultChecked
                            type='radio'
                            label="1"
                            id='num-of-servos-1'
                            name='num-of-servos'
                            disabled={formDisabled || mode === doubleSeqModeId || mode === krylanModeId}
                            checked={numOfServos === 1}
                            onChange={onSelectNumOfServos}
                          />
                          <Form.Check
                            type='radio'
                            label="2"
                            id='num-of-servos-2'
                            name='num-of-servos'
                            disabled={formDisabled || mode === doubleSeqModeId || mode === krylanModeId}
                            checked={numOfServos === 2}
                            onChange={onSelectNumOfServos}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <hr />
                <Row>
                  {numOfServos === 1 && (
                    <Canvas
                      width={400}
                      height={300}
                      mode={mode}
                      key={mode} // force re-render
                      firstSetter={setAngleInactive}
                      secondSetter={setAngleActive1}
                      thirdSetter={setAngleActive2}
                      fourthSetter={setAngleActive3}
                      defaultFirst={getDefaultAngle(0)}
                      defaultSecond={getDefaultAngle(1)}
                      defaultThird={getDefaultAngle(2)}
                      defaultFourth={getDefaultAngle(3)}
                      disabled={formDisabled}
                    />
                  )}
                  {numOfServos === 2 && (
                    <>
                      <Col>
                        <h4 className="d-flex justify-content-center servo-num">Канал #1</h4>
                        <Canvas
                          width={400}
                          height={300}
                          mode={mode}
                          key={mode}
                          firstSetter={setAngleInactive}
                          secondSetter={setAngleActive1}
                          thirdSetter={setAngleActive2}
                          fourthSetter={setAngleActive3}
                          defaultFirst={getDefaultAngle(0)}
                          defaultSecond={getDefaultAngle(1)}
                          defaultThird={getDefaultAngle(2)}
                          defaultFourth={getDefaultAngle(3)}
                          disabled={formDisabled}
                        />
                      </Col>
                      <Col>
                      <Row>
                      <h4 className="d-flex justify-content-center servo-num ch2">Канал #2</h4>
                      </Row>
                          <Canvas
                            width={400}
                            height={300}
                            mode={mode}
                            // key={`${mode}-${reRender}`}
                            // key={`${mode}-${angle2Inactive}-${angle2Active1}-${angle2Active2}-${angle2Active3}`} // force re-render
                            key={mode}
                            firstSetter={setAngle2Inactive}
                            secondSetter={setAngle2Active1}
                            thirdSetter={setAngle2Active2}
                            fourthSetter={setAngle2Active3}
                            defaultFirst={doMirror ? 180 - angleInactive : getDefaultAngle(0)}
                            defaultSecond={doMirror ? 180 - angleActive1 : getDefaultAngle(1)}
                            defaultThird={doMirror ? 180 - angleActive2 : getDefaultAngle(2)}
                            defaultFourth={doMirror ? 180 - angleActive3 : getDefaultAngle(3)}
                            hidden
                            disabled={formDisabled} // TODO
                          />
                      </Col>
                    </>
                  )}
                </Row>
                {/* <Canvas2 /> */}
                <hr />
                {(board === 'lite_v4' || board === 'v3' || board === 'v4') && (
                <>
                  <div className="input-container">
                      <label htmlFor="dynamic-power">Увімкнути динамічне керування живленням серви
                      <div id="info-gl-sensor" className="info-icon hide-on-small d-none d-md-block" title="Дана опція активує режим, коли плата в неактивному режимі буде повністю знімати живлення з серви, і буде його подавати виключно при активації. Це трішки збільшує час на спрацювання, але дає змогу крути серву з меншим супротивом. Якщо опція не вибрана, тоді серва буде завжди знаходитися під живлення (такий самий принцип роботи, як у Lite v3).">ℹ️</div>
                      <p className="hint d-block d-md-none">Дана опція активує режим, коли плата в неактивному режимі буде повністю знімати живлення з серви, і буде його подавати виключно при активації. Це трішки збільшує час на спрацювання, але дає змогу крути серву з меншим супротивом. Якщо опція не вибрана, тоді серва буде завжди знаходитися під живлення (такий самий принцип роботи, як у Lite v3).</p>
                    </label>
                      <div key="dynamic-power" className="mb-3">
                        <Row>
                          <Col>
                            <Form.Check
                              defaultChecked
                              type='radio'
                              label="Так"
                              id='dynamic-power-yes'
                              name='dynamic-power'
                              required
                              disabled={formDisabled}
                              checked={dynamicPower === true}
                              onChange={() => {setDynamicPower(true)}}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type='radio'
                              label="Ні"
                              id='dynamic-power-no'
                              name='dynamic-power'
                              required
                              disabled={formDisabled}
                              checked={dynamicPower === false}
                              onChange={() => {setDynamicPower(false)}}
                            />
                          </Col>
                        </Row>
                      </div>
                  </div>
                  <hr />
                </>
                )}
                <div className="input-container">
                  <label htmlFor="dynamic-power">Налаштування ШІМ</label>
                  <p className="hint">Дані налаштування дають змогу налаштувати ширину імпульсу ШІМ. Це дає змогу трішки збільшити робочі кути серви. Використовуйте з обережністю, бо дeякі серви можуть працювати не стабільно, якщо значення буде знаходитися занадто дале від робочих значень.</p>
                  <Select
                    id="shim-selector"
                    className="shim-selector mt-2"
                    value={shimOptions[shimMode - 1]}
                    // key={`${mode}-${numOfServos}`}
                    key={rnd}
                    defaultValue={shimOptions[0]}
                    options={shimOptions}
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={formDisabled}
                    onChange={onSelectShim}
                  />
                    <Row className="mt-3">
                        <Col md={6}>
                          <p id='xxx' style={{width: '250px', textAlign: 'center'}}>Мін. значення</p>
                        </Col>
                        <Col className="justify-content-end" md={6}>
                          <p id='yyy' style={{width: '250px', textAlign: 'center'}}>Макс. значення</p>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="shim-from"
                            className="input-field"
                            name="shim-from"
                            type="number"
                            step={1}
                            min={400}
                            max={2500}
                            defaultValue={400}
                            value={shimFrom}
                            onChange={onShimFromChange}
                            disabled={formDisabled || shimMode !== shimCustomModeId}
                            required
                          />
                        </InputGroup>
                      </Col>
                      <Col className="justify-content-end">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="shim-to"
                            className="input-field"
                            name="shim-to"
                            type="number"
                            step={1}
                            min={400}
                            max={2500}
                            defaultValue={2500}
                            value={shimTo}
                            onChange={onShimToChange}
                            disabled={formDisabled || shimMode !== shimCustomModeId}
                            required
                          />
                        </InputGroup>
                      </Col>
                  </Row>
                </div>
                <hr />
                <div className="input-container">
                  <label htmlFor="light-sensor-threshold">Чутливість фотосенсора (0 - 100%)</label>
                  <p className="hint">Порог спрацювання системи від датчика освітлення (чим більше значення, тим менша чутливість).</p>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="light-sensor-threshold"
                      className="input-field"
                      name="light-sensor-threshold"
                      key={`${lightSensorThresholdDefault}-${rnd}`} // force re-render on default value change
                      type="number"
                      step={0.01}
                      min={0}
                      max={100}
                      // defaultValue={['v3', 'v4'].includes(board) ? 98 : 97}
                      defaultValue={lightSensorThresholdDefault}
                      // value={lightSensorThreshold}
                      onChange={onChange}
                      disabled={formDisabled}
                      required
                    />
                  </InputGroup>
                  {renderErrorMessage("light-sensor-threshold")}
                </div>
                {['lite_v2', 'lite_v3', 'lite_v4', 'v3', 'v4'].includes(board) && (
                  <>
                    <hr />
                    <Led ledOnMs={ledOnMs} ledOffMs={ledOffMs}/>
                    <div className="input-container">
                      <label htmlFor="led-blink-on-ms">LED - увімкнено (ms)</label>
                      <p className="hint">Час в мілескундах, протягом якого світлодіод горить</p>
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="led-blink-on-ms"
                          className="input-field"
                          name="led-blink-on-ms"
                          type="number"
                          defaultValue={ledOnMs}
                          min={0}
                          max={10000}
                          onChange={onChange}
                          // required
                        />
                      </InputGroup>
                      {renderErrorMessage("led-blink-on-ms")}
                    </div>
                    <div className="input-container">
                      <label htmlFor="led-blink-off-ms">LED - вимкнено (ms)</label>
                      <p className="hint">Час в мілісекундах, протягом якого світлодіод не горить</p>
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="led-blink-off-ms"
                          className="input-field"
                          name="led-blink-off-ms"
                          type="number"
                          defaultValue={ledOffMs}
                          min={0}
                          max={10000}
                          onChange={onChange}
                          // required
                        />
                      </InputGroup>
                      {renderErrorMessage("led-blink-off-ms")}
                    </div>
                  </>
                )}
                {renderErrorMessage("general")}
                <Button type="submit" className="button-container" disabled={formDisabled}>
                  ⚙️ Build ⚙️
                </Button>
              </form>
            </div>
          )}
        </Col>
      </Row>
      <Snowflakes />
    </Container>
  );
};

export default Home;
